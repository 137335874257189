import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zmbush.com/zmbush.com/main/src/components/layouts/default.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query Get404Image {
    file(relativePath: { eq: "404.mdx" }) {
      mdx: childMdx {
        frontmatter {
          heroImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`This may refer to some deleted content, but is most likely a mistyped link. You might be able to
find what you're looking for by checking the pages in the header. Apologies for the
inconvenience.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      